<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">
            Autentimine
          </h1>
          <h2 class="subtitle">
            Logi sisse oma GP / GC kasutajaga
          </h2>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <section class="modal-card-body">
          <b-steps
            v-model="provider"
            size="is-large"
            :has-navigation="false">
            <b-step-item
              label="geopeitus.ee"
              value="gp"
              icon="account-key"
              :clickable="false"
              :type="{'is-success': gpAuthenticated}">
              <div class="columns">
                <div class="column is-one-third">
                  <login-form
                    @onLogin="login($event)"
                    :disabled="formDisabled"
                    provider="gp">
                  </login-form>
                </div>
              </div>
            </b-step-item>
            <b-step-item
              label="geocaching.com"
              value="gc"
              icon="account-key"
              :clickable="false"
              :type="{'is-success': gcAuthenticated}">
              <div class="columns">
                <div class="column is-one-third has-text-centered">
                  <b-icon
                    icon="account"
                    size="is-small"
                    type="is-success">
                  </b-icon>
                  <b>{{ gpAuthenticated ? user['gp'] : '' }}</b>
                </div>
                <div class="column is-one-third">
                  <login-form
                    @onLogin="login($event)"
                    :disabled="formDisabled"
                    provider="gc">
                  </login-form>
                </div>
              </div>
            </b-step-item>
            <b-step-item
              label=""
              value="result"
              icon="account-check"
              :clickable="false"
              :type="{'is-success': gcAuthenticated && gpAuthenticated}">
              <div class="columns">
                <div class="column is-one-third has-text-centered">
                  <b-icon
                    icon="account"
                    size="is-small"
                    type="is-success">
                  </b-icon>
                  <b>{{ gpAuthenticated ? user['gp'] : '' }}</b>
                </div>
                <div class="column is-one-third has-text-centered">
                  <b-icon
                    icon="account"
                    size="is-small"
                    type="is-success">
                  </b-icon>
                  <b>{{ gcAuthenticated ? user['gc'] : '' }}</b>
                </div>
                <div class="column is-one-third has-text-centered">
                  <b-button type="is-danger"
                    icon-left="logout-variant"
                    :disabled="formDisabled"
                    :loading="formDisabled"
                    @click="logout()">
                    Logi välja
                  </b-button>
                </div>
              </div>
            </b-step-item>
          </b-steps>
        </section>
      </div>
    </section>
  </div>
</template>

<script>

import LoginForm from '@/components/LoginForm.vue'
import authService from '@/services/AuthService'
import { error } from '@/utils'

export default {
  name: 'Auth',
  components: {
    LoginForm
  },
  data() {
    let data = JSON.parse(localStorage.getItem('user'))
    let provider = 'gp'
    if (data && data['gp']) {
      provider = 'gc'
    }
    if (data && data['gp'] && data['gc']) {
      provider = 'result'
    }
    return {
      provider: provider,
      formDisabled: false,
      gpAuthenticated: data && data.token && data['gp'],
      gcAuthenticated: data && data.token && data['gc'],
      user: data
    }
  },
  methods: {
    login (data) {
      this.formDisabled = true
      authService.login(data)
        .then(response => {
          if (response.ok) {
            switch (data.provider) {
              case 'gp':
                this.gpAuthenticated = true
                this.provider = 'gc'
                break;
              case 'gc':
                this.gcAuthenticated = true
                this.provider = 'result'
                break;
            }
            this.user = JSON.parse(localStorage.getItem('user'))
          }
        })
        .catch(error)
        .finally(() => {
          this.formDisabled = false
          if (this.gpAuthenticated && this.gcAuthenticated) {
            this.$events.$emit('auth', true)
          }
        })
    },
    logout () {
      this.formDisabled = true
      authService.logout()
        .then(() => {
          this.formDisabled = false
          this.gpAuthenticated = false
          this.gcAuthenticated = false
          this.provider = 'gp'
          this.$events.$emit('auth', false)
        })
        .catch(error)
        .finally(() => {
          this.formDisabled = false
        })
    }
  }
}
</script>
