<template>
  <form>
    <b-field label="Kasutaja">
      <b-input
        type="text"
        v-model="username"
        placeholder="Kasutaja"
        :disabled="disabled"
        required>
      </b-input>
    </b-field>
    <b-field label="Parool">
      <b-input
        type="password"
        v-model="password"
        password-reveal
        placeholder="Parool"
        :disabled="disabled"
        @keyup.native.enter="login()"
        required>
      </b-input>
    </b-field>
    <b-button type="is-primary"
      icon-left="login-variant"
      :disabled="disabled"
      :loading="disabled"
      @click="login()">
      Logi sisse
    </b-button>
  </form>
</template>

<script>
export default {
  name: 'LoginForm',
  props: {
    provider: String,
    disabled: Boolean
  },
  data() {
    return {
      username: '',
      password: ''
    }
  },
  methods: {
    validate() {
      return this.username.length && this.password.length
    },
    login () {
      if (this.validate()) {
        this.$emit('onLogin', {
          provider: this.provider,
          username: this.username,
          password: this.password
        })
      }
    }
  }
}
</script>
